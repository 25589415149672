/**
 * Created by jerry on 2020/03/30.
 * 产品消耗经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取产品消耗经手人设置列表
    getProductTreatHandler: params => {
        return API.POST('api/productTreatHandler/all', params)
    },
    // 创建产品消耗经手人设置
    createProductTreatHandler: params => {
        return API.POST('api/productTreatHandler/create', params)
    },
    // 更新产品消耗经手人设置
    updateProductTreatHandler: params => {
        return API.POST('api/productTreatHandler/update', params)
    },
    // 调整产品消耗经手人设置顺序
    moveProductTreatHandler: params => {
        return API.POST('api/productTreatHandler/move', params)
    },
}